import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserSettings } from "../../../../../hooks/api/useUserSettings";
import { SaveButton } from "../../../../SaveButton/SaveButton";
import { DropDownSelect } from "../../../../DropDownSelect/DropDownSelect";
import { PreferenceForm } from "../../../../Form/Form";
import { UserSetting } from "@coderone/library";
import { SuccessIndicator } from "../../../../SuccessIndicator/SuccessIndicator";

export const LevelPreference: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const { userQuery, mutation } = useUserSettings();
    const [level, setLevel] = useState<string | null>();
    const [isUpdated, setIsUpdated] = useState(false);

    const onLevelChanged = useCallback(
        (value: string) => {
            setLevel(value);
            setIsUpdated(false);
        },
        [setLevel, setIsUpdated]
    );

    const isDisabled = useMemo(() => {
        const hasChanged = level !== userQuery.data?.level;
        return hasChanged === false;
    }, [level, userQuery.data]);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();
            mutation.mutate({ setting: UserSetting.Level, value: level ?? null });
            setIsUpdated(true);
        },
        [mutation.mutate, level, setIsUpdated]
    );

    useEffect(() => {
        if (userQuery.data?.level !== undefined) {
            setLevel(userQuery.data.level);
        }
    }, [userQuery.data, setLevel]);

    const isLoading = useMemo(() => {
        return userQuery.status === "loading" || mutation.status === "loading";
    }, [userQuery.status, mutation.status]);

    return (
        <PreferenceForm onSubmit={onSubmit}>
            <DropDownSelect label={t("profilePage.level")} value={level ?? ""} onChange={(e) => onLevelChanged(e.target.value)}>
                <option key={0} value="">
                    {t("profilePage.select")}
                </option>
                <option key={1} value={t("profilePage.industry")}>
                    {t("profilePage.industry")}
                </option>
                <option key={2} value={t("profilePage.student")}>
                    {t("profilePage.student")}
                </option>
                <option key={3} value={t("profilePage.academia")}>
                    {t("profilePage.academia")}
                </option>
            </DropDownSelect>
            {isUpdated ? <SuccessIndicator /> : <SaveButton type="submit" disabled={isDisabled} loading={isLoading} />}
        </PreferenceForm>
    );
};
