import axios, { AxiosError } from "axios";
import { AuthContext } from "../../components/Auth/AuthContext";
import { getAxiosRequestConfig } from "../../utilities/getAxiosRequestConfig";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Constants } from "../../utilities/Constants";
import { UserSetting } from "@coderone/library";

interface IUserSettingsResponse {
    readonly weekly_leaderboard_email_enabled: boolean;
    readonly username: string | null;
    readonly discord: string | null;
    readonly website_url: string | null;
    readonly github: string | null;
    readonly linkedin: string | null;
    readonly twitter: string | null;
    readonly organization: string | null;
    readonly level: string | null;
}

const getUserSettings = async (signal: AbortSignal | undefined, token: string | null) => {
    const config = getAxiosRequestConfig(token, signal);
    const uri = `${Constants.ApiRoot}/user/settings`;
    try {
        const { data } = await axios.get<IUserSettingsResponse>(uri, config);
        return data;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError?.response?.status === 404) {
            return null;
        }
        throw error;
    }
};

const getMutationQuery = (token: string | null) => {
    const patchUserSetting = async (options: { readonly setting: UserSetting; readonly value: boolean | string | null }): Promise<{}> => {
        const { setting, value } = options;
        const config = getAxiosRequestConfig(token);
        const { data } = await axios.patch(`${Constants.ApiRoot}/user/settings`, { setting, value }, config);
        return data;
    };
    return patchUserSetting;
};

export const useUserSettings = () => {
    const queryClient = useQueryClient();
    const { token } = useContext(AuthContext);

    const mutation = useMutation(getMutationQuery(token), {
        onSuccess: (_data) => {
            queryClient.invalidateQueries(["userSettings"]);
        },
        onError: (_error, _variables, _context) => {},
    });

    const userQuery = useQuery(["userSettings"], async ({ signal }) => {
        return await getUserSettings(signal, token);
    });
    return { userQuery, mutation };
};
