import * as React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { DropDownSelect } from "../../../../DropDownSelect/DropDownSelect";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "../../../../Auth/AuthContext";
import { getAxiosRequestConfig } from "../../../../../utilities/getAxiosRequestConfig";
import { Constants } from "../../../../../utilities/Constants";
import { useState, useCallback, useContext, useEffect, useMemo } from "react";
import { SaveButton } from "../../../../SaveButton/SaveButton";
import { telemetry } from "../../../../../utilities/Telemetry/Telemetry";
import { TelemetryEvent } from "../../../../../utilities/Telemetry/TelemetryEvent";
import { CountryCode } from "./Country.types";
import { SuccessIndicator } from "../../../../SuccessIndicator/SuccessIndicator";
import { useUser } from "../../../../../hooks/api/user/useUser";
import { PreferenceForm } from "../../../../Form/Form";

const putUserCountry = async (token: string | null, newUserCountry: string): Promise<unknown> => {
    const config = getAxiosRequestConfig(token);
    const { data } = await axios.put(`${Constants.ApiRoot}/user`, { country: newUserCountry }, config);
    return data;
};

export const CountryPicker: React.FC<React.PropsWithChildren<unknown>> = () => {
    const queryClient = useQueryClient();
    const [t] = useTranslation();
    const { token } = useContext(AuthContext);
    const { isLoading: isUserLoading, data: userData } = useUser();
    const [country, setCountry] = useState("");
    const [isUserCountryUpdated, setIsUserCountryUpdated] = useState(false);
    const fetchedCountry = userData?.country;

    useEffect(() => {
        if (fetchedCountry) {
            setCountry(fetchedCountry);
        }
    }, [fetchedCountry, setCountry]);

    const { mutate: updateUserCountry, status: updateUserCountryStatus } = useMutation(() => putUserCountry(token, country), {
        onSuccess: (_data) => {
            queryClient.invalidateQueries(["user"]);
            telemetry.Log(TelemetryEvent.UpdateCountrySuccess, null);
            setIsUserCountryUpdated(true);
        },
        onError: (error, _variables, _context) => {
            const e = error as any;
            const updateError = e?.response?.data?.error;
            telemetry.Log(TelemetryEvent.UpdateCountryError, updateError);
        },
    });

    const onCountryChanged = useCallback(
        (value: string) => {
            setCountry(value);
            setIsUserCountryUpdated(false);
        },
        [setCountry, setIsUserCountryUpdated]
    );

    const onUpdateCountry: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();
            updateUserCountry();
            telemetry.Log(TelemetryEvent.UpdateCountryInit, null);
        },
        [country, updateUserCountry]
    );

    const isUpdateCountryDisabled = useMemo(() => {
        return country === fetchedCountry;
    }, [country, fetchedCountry]);

    const isLoading = useMemo(() => {
        return isUserLoading || updateUserCountryStatus === "loading";
    }, [isUserLoading, updateUserCountryStatus]);

    return (
        <PreferenceForm onSubmit={onUpdateCountry}>
            <DropDownSelect label={t("profilePage.country")} value={country} onChange={(e) => onCountryChanged(e.target.value)}>
                <option></option>
                {(Object.keys(CountryCode) as Array<keyof typeof CountryCode>).map((key) => {
                    return (
                        <option key={key} value={key}>
                            {CountryCode[key]}
                        </option>
                    );
                })}
            </DropDownSelect>
            {isUserCountryUpdated ? (
                <SuccessIndicator />
            ) : (
                <SaveButton type="submit" disabled={isUpdateCountryDisabled} loading={isLoading} />
            )}
        </PreferenceForm>
    );
};
