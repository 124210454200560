import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserSettings } from "../../../../../hooks/api/useUserSettings";
import { SaveButton } from "../../../../SaveButton/SaveButton";
import { TextInput } from "../../../../TextInput/TextInput";
import { PreferenceForm } from "../../../../Form/Form";
import { UserSetting } from "@coderone/library";
import { SuccessIndicator } from "../../../../SuccessIndicator/SuccessIndicator";

export const GithubPreference: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const { userQuery, mutation } = useUserSettings();
    const [github, setGithub] = useState<string | null>();
    const [isUpdated, setIsUpdated] = useState(false);

    const onGithubChanged = useCallback(
        (_: unknown, value: string) => {
            setGithub(value);
            setIsUpdated(false);
        },
        [setGithub, setIsUpdated]
    );

    const isDisabled = useMemo(() => {
        const hasChanged = github !== userQuery.data?.github;
        return hasChanged === false;
    }, [github, userQuery.data]);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();
            mutation.mutate({ setting: UserSetting.Github, value: github ?? null });
            setIsUpdated(true);
        },
        [mutation.mutate, github, setIsUpdated]
    );

    useEffect(() => {
        if (userQuery.data?.github !== undefined) {
            setGithub(userQuery.data.github);
        }
    }, [userQuery.data, setGithub]);

    const isLoading = useMemo(() => {
        return userQuery.status === "loading" || mutation.status === "loading";
    }, [userQuery.status, mutation.status]);

    return (
        <PreferenceForm onSubmit={onSubmit}>
            <TextInput
                type="text"
                value={github ?? ""}
                onChange={onGithubChanged}
                label={t("github")}
                placeholder={t("profilePage.usernamePlaceholder")}
                prefixAdornment={t("profilePage.githubAdornment")}
            />
            {isUpdated ? <SuccessIndicator /> : <SaveButton type="submit" disabled={isDisabled} loading={isLoading} />}
        </PreferenceForm>
    );
};
