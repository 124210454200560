import axios from "axios";
import * as React from "react";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useUser } from "../../../../hooks/api/user/useUser";
import { Constants } from "../../../../utilities/Constants";
import { getAxiosRequestConfig } from "../../../../utilities/getAxiosRequestConfig";
import { telemetry } from "../../../../utilities/Telemetry/Telemetry";
import { TelemetryEvent } from "../../../../utilities/Telemetry/TelemetryEvent";
import { AuthContext } from "../../../Auth/AuthContext";
import { ContentCard } from "../../../ContentCard/ContentCard";
import { Form } from "../../../Form/Form";
import { H2 } from "../../../H2/H2";
import { Spinner } from "../../../Spinner/Spinner";
import { StateButton } from "../../../StateButton/StateButton";
import { TextInput } from "../../../TextInput/TextInput";
import { Root } from "./Account.styles";
import { CountryPicker } from "./CountryPicker/CountryPicker";
import { DiscordPreference } from "./DiscordPreference/DiscordPreference";
import { EmailPreferences } from "./EmailPreferences/EmailPreferences";
import { GithubPreference } from "./GithubPreference/GithubPreference";
import { LinkedinPreference } from "./LinkedinPreference/LinkedinPreference";
import { TwitterPreference } from "./TwitterPreference/TwitterPreference";
import { UsernamePreference } from "./UsernamePreference/UsernamePreference";
import { WebsitePreference } from "./WebsitePreference/WebsitePreference";
import { OrganizationPreference } from "./OrganizationPreference/OrganizationPreference";
import { LevelPreference } from "./LevelPreference/LevelPreference";
interface IPatchUserPasswordResponse {}

const patchUserPassword = async (token: string | null, password: string): Promise<IPatchUserPasswordResponse> => {
    const config = getAxiosRequestConfig(token);
    const request = axios.patch(`${Constants.ApiRoot}/user/password`, { password }, config);

    const { data } = await request;
    return data;
};

export const AccountContent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const { token } = useContext(AuthContext);
    const { isLoading, data } = useUser();
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [submissionError, setSubmissionError] = useState("");

    const { mutate, status: resetPasswordStatus } = useMutation(() => patchUserPassword(token, password), {
        onSuccess: (_) => {
            telemetry.Log(TelemetryEvent.ChangePasswordSuccess, null);
        },
        onError: (error, _variables, _context) => {
            const e = error as any;
            const resetPasswordError = e?.response?.data?.error;
            setSubmissionError(resetPasswordError);
            telemetry.Log(TelemetryEvent.ChangePasswordError, resetPasswordError);
        },
    });

    const onPasswordChanged = useCallback(
        (_: unknown, value: string) => {
            setPassword(value);
        },
        [setPassword]
    );

    const onConfirmPasswordChanged = useCallback(
        (_: unknown, value: string) => {
            setPasswordConfirmation(value);
        },
        [setPasswordConfirmation]
    );

    const onChangePasswordSubmitted: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();
            telemetry.Log(TelemetryEvent.ChangePasswordInit, null);
            mutate();
        },
        [password]
    );

    const isChangePasswordDisabled = useMemo(() => {
        return password.length <= 0 || password !== passwordConfirmation;
    }, [password, passwordConfirmation]);

    const isConfirmationEnabled = useMemo(() => {
        return password.length <= 0;
    }, [password, passwordConfirmation]);

    if (isLoading || resetPasswordStatus === "loading") {
        return <Spinner />;
    }
    return (
        <Root>
            <ContentCard>
                <H2>{t("email")}</H2>
                {data?.email}
            </ContentCard>
            <ContentCard>
                <H2>{t("profilePage.settings")}</H2>
                <UsernamePreference />
                <GithubPreference />
                <DiscordPreference />
                <LinkedinPreference />
                <TwitterPreference />
                <WebsitePreference />
                <OrganizationPreference />
                <LevelPreference />
                <CountryPicker />
            </ContentCard>
            <ContentCard>
                <H2>{t("changePassword")}</H2>
                {resetPasswordStatus === "success" ? (
                    t("passwordChangedSuccess")
                ) : (
                    <Form onSubmit={onChangePasswordSubmitted}>
                        <TextInput
                            placeholder={t("newPassword")}
                            value={password}
                            onChange={onPasswordChanged}
                            label={t("newPassword")}
                            type="password"
                            required
                        />
                        <TextInput
                            placeholder={t("confirmPassword")}
                            value={passwordConfirmation}
                            onChange={onConfirmPasswordChanged}
                            label={t("confirmPassword")}
                            type="password"
                            readOnly={isConfirmationEnabled}
                            required
                        />
                        <StateButton type="submit" disabled={isChangePasswordDisabled}>
                            {t("changePassword")}
                        </StateButton>
                        {submissionError !== undefined && submissionError}
                    </Form>
                )}
            </ContentCard>
            <ContentCard>
                <EmailPreferences />
            </ContentCard>
        </Root>
    );
};
