import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserSettings } from "../../../../../hooks/api/useUserSettings";
import { SaveButton } from "../../../../SaveButton/SaveButton";
import { TextInput } from "../../../../TextInput/TextInput";
import { PreferenceForm } from "../../../../Form/Form";
import { UserSetting } from "@coderone/library";
import { SuccessIndicator } from "../../../../SuccessIndicator/SuccessIndicator";

export const TwitterPreference: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const { userQuery, mutation } = useUserSettings();
    const [twitter, setTwitter] = useState<string | null>();
    const [isUpdated, setIsUpdated] = useState(false);

    const onTwitterChanged = useCallback(
        (_: unknown, value: string) => {
            setTwitter(value);
            setIsUpdated(false);
        },
        [setTwitter, setIsUpdated]
    );

    const isDisabled = useMemo(() => {
        const hasChanged = twitter !== userQuery.data?.twitter;
        return hasChanged === false;
    }, [twitter, userQuery.data]);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();
            mutation.mutate({ setting: UserSetting.Twitter, value: twitter ?? null });
            setIsUpdated(true);
        },
        [mutation.mutate, twitter, setIsUpdated]
    );

    useEffect(() => {
        if (userQuery.data?.twitter !== undefined) {
            setTwitter(userQuery.data.twitter);
        }
    }, [userQuery.data, setTwitter]);

    const isLoading = useMemo(() => {
        return userQuery.status === "loading" || mutation.status === "loading";
    }, [userQuery.status, mutation.status]);

    return (
        <PreferenceForm onSubmit={onSubmit}>
            <TextInput
                type="text"
                value={twitter ?? ""}
                onChange={onTwitterChanged}
                label={t("twitter")}
                placeholder={t("profilePage.usernamePlaceholder")}
                prefixAdornment={t("profilePage.twitterAdornment")}
            />
            {isUpdated ? <SuccessIndicator /> : <SaveButton type="submit" disabled={isDisabled} loading={isLoading} />}
        </PreferenceForm>
    );
};
