import * as React from "react";
import styled from "styled-components";
import { Palette } from "../../theme/Palette";
import { Spinner } from "../Spinner/Spinner";

export const ButtonRoot = styled.button`
    position: relative;
    border-radius: 4px;
    width: 36px;
    height: 34px;
    display: inline-block;
    grid-row-end: span 2;

    ${(props) =>
        props.disabled
            ? `
    background-color: ${Palette.Neutral20};
    font-color: ${Palette.Neutral60};
    border: 1px solid ${Palette.Neutral20};
    `
            : `
    cursor: pointer;
    background-color: ${Palette.Primary10};
    border-color: ${Palette.Primary100};
    color: ${Palette.Primary100};
    border: 1px solid ${Palette.Primary100};
    transition: background-color 150ms ease;

    :hover {
        background-color: ${Palette.Primary100};
        color: ${Palette.Neutral0};
    }

    `};
`;

const IconRoot = styled.span`
    vertical-align: middle;
    display: inline-block;
    line-height: 100%;
`;

interface IButtonProps {
    readonly type?: "button" | "submit" | "reset" | undefined;
    readonly disabled?: boolean;
    readonly loading?: boolean;
}

export const SaveButton: React.FC<React.PropsWithChildren<IButtonProps>> = ({ type = "submit", disabled, loading }) => {
    return (
        <ButtonRoot type={type} disabled={disabled}>
            {loading ? (
                <Spinner color={Palette.Primary100} fontSize="1em" />
            ) : (
                <IconRoot>
                    <svg xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" viewBox="0 0 48 48" fill="currentColor">
                        <path d="M42 13.85V39q0 1.2-.9 2.1-.9.9-2.1.9H9q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h25.15Zm-3 1.35L32.8 9H9v30h30ZM24 35.75q2.15 0 3.675-1.525T29.2 30.55q0-2.15-1.525-3.675T24 25.35q-2.15 0-3.675 1.525T18.8 30.55q0 2.15 1.525 3.675T24 35.75ZM11.65 18.8h17.9v-7.15h-17.9ZM9 15.2V39 9Z" />
                    </svg>
                </IconRoot>
            )}
        </ButtonRoot>
    );
};
