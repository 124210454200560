import { Toggle } from "@fluentui/react";
import * as React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useUserSettings } from "../../../../../hooks/api/useUserSettings";
import { H2 } from "../../../../H2/H2";
import { Spinner } from "../../../../Spinner/Spinner";
import { UserSetting } from "@coderone/library";

export const EmailPreferences: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const { userQuery, mutation } = useUserSettings();

    const onWeeklyLeaderboardToggled = useCallback(
        (_: unknown, checked: boolean | undefined) => {
            mutation.mutate({ setting: UserSetting.WeeklyLeaderboardEmailEnabled, value: checked ?? null });
        },
        [mutation.mutate]
    );
    if (userQuery.status === "loading" || mutation.status === "loading") {
        return <Spinner />;
    }
    return (
        <React.Fragment>
            <H2>{t("profilePage.emailPreferences")}</H2>
            <Toggle
                label={t("profilePage.weeklyLeaderboardEmail")}
                onText={t("on")}
                offText={t("off")}
                checked={userQuery.data?.weekly_leaderboard_email_enabled}
                onChange={onWeeklyLeaderboardToggled}
            />
        </React.Fragment>
    );
};
