import * as React from "react";
import styled from "styled-components";
import { Palette } from "../../theme/Palette";
import { AnimatedCheckedIcon } from "./AnimatedCheckedIcon";

const Root = styled.div`
    background-color: ${Palette.Success10};
    color: ${Palette.Success100};
    position: relative;
    border-radius: 4px;
    padding: 10px 12px;
    display: inline-block;
    grid-row-end: span 2;
`;

export const SuccessIndicator: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <Root>
            <AnimatedCheckedIcon />
        </Root>
    );
};
