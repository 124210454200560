import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useUserSettings } from "../../../../../hooks/api/useUserSettings";
import { TextInput } from "../../../../TextInput/TextInput";
import { PreferenceForm } from "../../../../Form/Form";
import { Text } from "@fluentui/react";
import { CoderOneRoute } from "../../../../Pages/CoderOneRoute";
import { isValidUsername, UserSetting } from "@coderone/library";
import { RouterLink } from "../../../../RouterLink/RouterLink";
import { SuccessIndicator } from "../../../../SuccessIndicator/SuccessIndicator";
import { SaveButton } from "../../../../SaveButton/SaveButton";

export const UsernamePreference: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const { userQuery, mutation } = useUserSettings();
    const [username, setUsername] = useState<string | null>();
    const [isUpdated, setIsUpdated] = useState(false);

    const onUsernameChanged = useCallback(
        (_: unknown, value: string) => {
            setUsername(value);
            setIsUpdated(false);
        },
        [setUsername, setIsUpdated]
    );

    const isDisabled = useMemo(() => {
        const hasChanged = username !== userQuery.data?.username;
        return isValidUsername(username ?? "") === false || hasChanged === false;
    }, [username, userQuery.data]);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();
            mutation.mutate({ setting: UserSetting.Username, value: username ?? null });
            setIsUpdated(true);
        },
        [mutation.mutate, username, setIsUpdated]
    );

    useEffect(() => {
        if (userQuery.data?.username !== undefined) {
            setUsername(userQuery.data.username);
        }
    }, [userQuery.data, setUsername]);

    const isLoading = useMemo(() => {
        return userQuery.status === "loading" || mutation.status === "loading";
    }, [userQuery.status, mutation.status]);

    const publicUrl = `https://${process.env.GATSBY_HOST}${CoderOneRoute.ProfileRoute(username ?? "")}`;
    return (
        <>
            <PreferenceForm onSubmit={onSubmit}>
                <TextInput type="text" value={username ?? ""} onChange={onUsernameChanged} label={t("profilePage.usernamePreference")} />
                {isUpdated ? <SuccessIndicator /> : <SaveButton type="submit" disabled={isDisabled} loading={isLoading} />}
            </PreferenceForm>
            <Text>
                <Trans key="profilePage.publicUrl" options>
                    Your public url: <RouterLink href={CoderOneRoute.ProfileRoute(username ?? "")}>{publicUrl}</RouterLink>
                </Trans>
            </Text>
        </>
    );
};
