import * as React from "react";
import styled from "styled-components";
import { Palette } from "../../theme/Palette";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    grid-row-end: span 2;
`;

const Select = styled.select`
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 8px;
    font-family: inherit;
    color: inherit;
    background-color: ${Palette.Neutral10};

    option {
        color: inherit;
        background: ${Palette.Neutral0};
    }

    :focus {
        outline: none;
        border: 1px solid ${Palette.Primary100};
        box-shadow: ${Palette.Primary10} 0px 0px 0px 3px;
    }
`;

const Label = styled.span`
    font-weight: 600;
    margin-bottom: 4px;
`;

interface IProps {
    readonly onChange: (e: any) => void;
    readonly value: string | undefined;
    readonly label?: string;
}

export const DropDownSelect: React.FC<React.PropsWithChildren<IProps>> = ({ value, onChange, label, children }) => {
    return (
        <Root>
            {label && <Label>{label}</Label>}
            <Select value={value} onChange={onChange}>
                {children}
            </Select>
        </Root>
    );
};
