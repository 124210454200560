import * as React from "react";
import { useTranslation } from "react-i18next";
import { AuthenticatedFrame } from "../../../AuthenticatedFrame/AuthenticatedFrame";
import { WithAuth } from "../../../Auth/WithAuth";
import { SEO } from "../../../SEO/SEO";
import { AccountContent } from "./AccountContent";

const Account: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const title = t("account");
    const description = t("account");
    return (
        <WithAuth>
            <SEO description={description} title={title} />
            <AuthenticatedFrame title={t("account")}>
                <AccountContent />
            </AuthenticatedFrame>
        </WithAuth>
    );
};

export default Account;
