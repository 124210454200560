import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserSettings } from "../../../../../hooks/api/useUserSettings";
import { SaveButton } from "../../../../SaveButton/SaveButton";
import { TextInput } from "../../../../TextInput/TextInput";
import { PreferenceForm } from "../../../../Form/Form";
import { UserSetting } from "@coderone/library";
import { SuccessIndicator } from "../../../../SuccessIndicator/SuccessIndicator";

export const WebsitePreference: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const { userQuery, mutation } = useUserSettings();
    const [website, setWebsite] = useState<string | null>();
    const [isUpdated, setIsUpdated] = useState(false);

    const onWebsiteChanged = useCallback(
        (_: unknown, value: string) => {
            setWebsite(value);
            setIsUpdated(false);
        },
        [setWebsite, setIsUpdated]
    );

    const isDisabled = useMemo(() => {
        const hasChanged = website !== userQuery.data?.website_url;
        return hasChanged === false;
    }, [website, userQuery.data]);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();
            mutation.mutate({ setting: UserSetting.WebsiteUrl, value: website ?? null });
            setIsUpdated(true);
        },
        [mutation.mutate, website, setIsUpdated]
    );

    useEffect(() => {
        if (userQuery.data?.website_url !== undefined) {
            setWebsite(userQuery.data.website_url);
        }
    }, [userQuery.data, setWebsite]);

    const isLoading = useMemo(() => {
        return userQuery.status === "loading" || mutation.status === "loading";
    }, [userQuery.status, mutation.status]);

    return (
        <PreferenceForm onSubmit={onSubmit}>
            <TextInput
                type="text"
                value={website ?? ""}
                onChange={onWebsiteChanged}
                label={t("websiteUrl")}
                placeholder={t("profilePage.websitePlaceholder")}
            />
            {isUpdated ? <SuccessIndicator /> : <SaveButton type="submit" disabled={isDisabled} loading={isLoading} />}
        </PreferenceForm>
    );
};
