import * as React from "react";
import styled, { keyframes } from "styled-components";
import { Palette } from "../../theme/Palette";

const stroke = keyframes`
    100% {
        stroke-dashoffset: 0
    }
`;

const fill = keyframes`
    100% {
        box-shadow: inset 0px 0px 0px 30px ${Palette.Success100}
`;

const Svg = styled.svg`
    width: 1em;
    height: 1em;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: ${Palette.Neutral0};
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px ${Palette.Success100};
    animation: ${fill} 0.4s ease-in-out 0.4s forwards;
`;

const Circle = styled.circle`
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: ${Palette.Success100};
    fill: none;
    animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
`;

const Path = styled.path`
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
`;

export const AnimatedCheckedIcon: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <Circle cx="26" cy="26" r="25" fill="none" />
            <Path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </Svg>
    );
};
